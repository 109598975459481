
import Vue from "vue";

import Container from "@/components/Container.vue";
import DnsEnvironment from "@/views/Settings/DnsEnvironment.vue";
import Hero from "@/components/Hero.vue";
import ModulesEnvironment from "@/views/Settings/ModulesEnvironment.vue";
import Setting from "@/views/Settings/Setting.vue";

export default Vue.extend({
  components: {
    Container,
    DnsEnvironment,
    Hero,
    ModulesEnvironment,
    Setting,
  },
});
