<template>
  <select v-model="modulesEnvironment">
    <option v-for="e in environments" :key="e" :value="e">
      {{ e }}
    </option>
  </select>
</template>

<script>
import { mapMutations } from "vuex";

import { SET_MODULES_ENVIRONMENT } from "@/store/types/mutations";

export default {
  data() {
    return {
      environments: ["test", "staging", "prod"],
    };
  },
  computed: {
    modulesEnvironment: {
      get() {
        return this.$store.state.settings.modulesEnvironment;
      },
      set(newVal) {
        this.SET_MODULES_ENVIRONMENT(newVal);
      },
    },
  },
  methods: {
    ...mapMutations([SET_MODULES_ENVIRONMENT]),
  },
};
</script>
