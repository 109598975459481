<template>
  <div>
    <h3 class="h6 mb-1">{{ title }}</h3>
    <div class="flex items-start">
      <div class="flex-1 mr-2">
        <p>{{ description }}</p>
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "Setting",
  props: {
    description: String,
    title: String,
  },
};
</script>
